#mdl_summary {
    flex-direction: column;
}

#mdl_summary .react-datepicker-wrapper {
    margin-right: 4.5rem;
    margin-left: auto;
    width: 9rem;
}

#mdl_summary .category_date {
    margin-left: auto;
    margin-right: 11.5rem;
}

#mdl_summary .category_summary {
    position: absolute;
    right: 3rem;
}

#mdl_summary .item_credits {
    right: 2rem;
}

#mdl_summary .item_credits input {
    width: 5.5rem;
    text-align: right;
}

#mdl_summary .category_wrapper {
    min-height: 5.375rem;
    padding: 1rem;
    gap: 0.75rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--color-gray-50);
    border: 0.0625rem dashed var(--color-gray-300);
    border-radius: 0.375rem;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
}

#mdl_summary .category_header {
    width: 100%;
}

#mdl_summary .category_header .svg {
    width: 1.125rem;
    height: 1.125rem;
    margin-right: 0.5rem;
}

#mdl_summary .item_wrapper {
    border-top: 1px solid var(--color-gray-300);
    padding-top: 0.75rem;
    width: 100%;
    justify-content: space-between;
}
