#crd_external_wallet .card__content .button_group,
#crd_external_wallet .card__content .badge_group {
    gap: 1rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

#crd_external_wallet .card__content .button_group button {
    margin-left: unset;
    max-width: 14.25rem;
}

#crd_external_wallet .store_badge {
    padding: unset;
    border: unset;
    border-radius: unset;
    box-sizing: unset;
    box-shadow: unset;
    background: none;
    margin: unset;
}

#crd_external_wallet .store_badge__img_apple { 
    max-width: 7.5rem;
}

#crd_external_wallet .store_badge__img_google {
    max-width: 8.5rem;
}

@media only screen and (max-width: 67.5rem) {

    #crd_external_wallet .card__content .button_group {
        flex-direction: column;
    }

}

