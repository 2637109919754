#crd__issuance {
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
}

#crd__issuance > div.issuance__wrapper {
    margin: 1.5rem;
    max-width: 25rem;
    min-width: 17rem;
    flex-direction: column;
    background-color: var(--color-white);
    padding: 2rem 2.5rem;
    align-self: center;
}

#crd__issuance .issuance__title {
    max-width: 23rem;
    align-self: center;
    text-align: center;
}

#crd__issuance .issuance__description {
    margin-top: 1rem;
    align-self: center;
}

#crd__issuance div.qrcode_body {
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;
    gap: 0.5rem;
}

#crd__issuance div.qrcode_wrapper {
    position: relative;
}

#crd__issuance > div.issuance__wrapper > canvas.qrcode {
    margin-top: 1.5rem;
    align-self: center;
    width: 12.5rem;
    height: 12.5rem;
}